.bloglist {
  padding-bottom: 100px;
  margin-top: 67px;
}

.bloglist__article__title {
  font-size: 26px;
  font-weight: 500;
  padding-right: 20px;
  color: black;
}

.bloglist__article__link {
  text-decoration: none;
}

.bloglist__article__link:hover {
  text-decoration: none;
}

.bloglist__article__link:active {
  text-decoration: none;
}

.bloglist__article__link:visited {
  text-decoration: none;
}

.bloglist__article__date {
  font-size: 18px;
  font-weight: 500;
  padding-right: 20px;
  color: gray;
}